import { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import { Checkbox } from "@material-ui/core";

import Loader from '../../LoadingAnimation';
import { StyledTableCell, StyledTableRow, useStyles } from '../Mails/style';
import { getContactedList } from '../../../apis';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../actions';
import { HospitalDropDown } from '../../Shared/HospitalDropDown';
import { HOSPITALS, UserState, USER_ROLES } from '../../../@types';

interface ContactedListDataType {
  contactNumber?: number;
  email?: string;
  message?: string;
  name?: string;
  replied?: boolean;
  subject?: string;
  _id?: string;
}

export default function Contacts() {
  const classes = useStyles();

  const [contactsList, setContactsList] = useState<ContactedListDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedHospital, setSelectedHospital] = useState<HOSPITALS>(
    HOSPITALS.PROVIDENCE_HOSPITAL_ALAPPUZHA
  );

  const {
    user: {
      details: { role },
    },
  } = useSelector((state: UserState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    let isCancelled = false;

    (async () => {
      setLoading(true);

      let response: any;
      try {
        if (role === USER_ROLES.ADMIN) {
          const { data } = await getContactedList(
            currentPage,
            selectedHospital
          );
          response = data;
        } else {
          const { data } = await getContactedList(currentPage);
          response = data;
        }

        if (!isCancelled) {
          setPages(response.totalPages);
          setContactsList(response.contactedList);
          setLoading(false);
        }
      } catch (e) {
        if (!isCancelled) {
          setLoading(false);
          setContactsList([]);
          dispatch(logoutUser());
        }
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [currentPage, dispatch, role, selectedHospital]);

  if (loading) {
    return <Loader />;
  }

  if (!loading && !contactsList.length) {
    return (
      <>
        {role === USER_ROLES.ADMIN && (
          <HospitalDropDown
            setSelectedHospital={setSelectedHospital}
            selectedHospital={selectedHospital}
          />
        )}
        <h2>No Contacts</h2>
      </>
    );
  }

  return (
    <>
      {role === USER_ROLES.ADMIN && (
        <HospitalDropDown
          setSelectedHospital={setSelectedHospital}
          selectedHospital={selectedHospital}
        />
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ minWidth: '170px' }}>
                Name
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '190px' }}>
                Email
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '170px' }}>
                Contact Number
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '230px' }}>
                Subject
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '380px' }}>
                Message
              </StyledTableCell>
              {/* <StyledTableCell>Replied</StyledTableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {contactsList.map(
              ({
                _id,
                contactNumber,
                email,
                message,
                name,
                replied,
                subject,
              }) => {
                return (
                  <StyledTableRow key={_id}>
                    <StyledTableCell>{name}</StyledTableCell>

                    <StyledTableCell>{email}</StyledTableCell>

                    <StyledTableCell>{contactNumber}</StyledTableCell>
                    <StyledTableCell>{subject}</StyledTableCell>
                    <StyledTableCell>{message}</StyledTableCell>
                    {/* <StyledTableCell>
                      <Checkbox name="checkedD" checked={replied} disabled />
                    </StyledTableCell> */}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pages && (
        <div className="pagination-container">
          <Pagination
            onChange={(_event: object, page: number) => setCurrentPage(page)}
            count={pages > 10 ? 10 : pages}
            shape="rounded"
            page={currentPage}
          />
        </div>
      )}
    </>
  );
}
