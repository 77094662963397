import React from "react";
import { Route } from "react-router-dom";

import { Hompage } from "../components/Screen";

export const Routes: React.FC = () => {
  return (
    <React.Fragment>
      <Route exact path="/" component={Hompage} />
      <Route exact component={() => <h1>HEdllo</h1>} path="/test" />
    </React.Fragment>
  );
};
