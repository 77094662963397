import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { HOSPITALS, USER_ROLES } from "../../../@types";
import { Dispatch, SetStateAction } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface UserRoleDropDownProps {
  setUserRole: Dispatch<SetStateAction<string>>;
  value: string;
}

export function UserRoleDropDown(props: UserRoleDropDownProps) {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="grouped-select">User Role</InputLabel>
        <Select
          onChange={(e) => {
            if (typeof e.target.value === "string") {
              props.setUserRole(e.target.value);
            }
          }}
          value={props.value}
          id="grouped-select role"
        >
          <MenuItem value={USER_ROLES.ADMIN}>Admin</MenuItem>
          <MenuItem value={USER_ROLES.CO_WORKER}>Co Worker</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

interface HospitalDropDownProps {
  setHospital: Dispatch<SetStateAction<string>>;
  value: string;
}

export function HospitalsDropDown(props: HospitalDropDownProps) {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="grouped-select">Select hospital</InputLabel>
        <Select
          onChange={(e) => {
            if (typeof e.target.value === "string") {
              props.setHospital(e.target.value);
            }
          }}
          value={props.value}
          id="grouped-select hsp"
        >
          <MenuItem value={HOSPITALS.POYANIL_HOSPITAL_KOZHENCHERRY}>
            Poyanil Hospital Kozhencherry
          </MenuItem>
          <MenuItem value={HOSPITALS.POYANIL_HOSPITAL_PUNALUR}>
            Poyanil Hospital Punalur
          </MenuItem>
          <MenuItem value={HOSPITALS.PROVIDENCE_HOSPITAL_ALAPPUZHA}>
            Providence Hospital Alappuzha
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
