import { useState } from "react";
import { useSelector } from "react-redux";
import AuthWindow from "../Auth/AuthWindow";
import DashBoard from "../Dashboard/index";
import Layout from "../Layouts";

export default function HomePage(): JSX.Element {
  const {
    user: { isLogged },
  } = useSelector((state: { user: { isLogged: boolean } }) => state);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  return (
    <Layout setShowDrawer={setShowDrawer} showDrawerState={showDrawer}>
      {isLogged ? (
        <DashBoard openDrawer={showDrawer} setDrawerOpenState={setShowDrawer} />
      ) : (
        <AuthWindow />
      )}
    </Layout>
  );
}
