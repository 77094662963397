import { AUTH, UserModel } from "../@types";

export const loginUserInit = () => ({ type: AUTH.INIT_LOGIN });

export const loginUser = (payload: {
  isLogged: boolean;
  details: UserModel;
}) => ({
  type: AUTH.LOGIN,
  payload,
});

export const authError = (payload: { error: null | string }) => ({
  type: AUTH.AUTH_ERROR,
  payload,
});

export const authSuccess = (payload: { success: null | string }) => ({
  type: AUTH.AUTH_ERROR,
  payload,
});

export const logoutUser = () => ({ type: AUTH.LOGOUT });
