import { Dispatch, SetStateAction } from 'react';
import { compose } from 'redux';

export { FORM_NAMES } from './enums';

export interface HeaderProps {
  branding: {
    title: string;
    logo: string;
  };
  isLogged: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
  showDrawerState: boolean;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export { AUTH } from './enums/actions';

export enum USER_ROLES {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  CO_WORKER = 'co_worker',
}

export enum HOSPITALS {
  PROVIDENCE_HOSPITAL_ALAPPUZHA = 'Providence Hospital Alappuzha',
  POYANIL_HOSPITAL_KOZHENCHERRY = 'Poyanil Hospital Kozhencherry',
  POYANIL_HOSPITAL_PUNALUR = 'Poyanil Hospital Punalur',
}

export interface UserModel {
  email?: string;
  role?: USER_ROLES;
  hospital?: HOSPITALS;
  _id?: string;
}

export interface UserState {
  user: { isLogged: boolean; details: { hospital: string; role: string } };
}
