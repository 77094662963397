import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { AUTH, FORM_NAMES } from '../@types';
import { authError, loginUser, authSuccess } from '../actions';
import { login } from '../apis';

const getValues = ({
  form: {
    //@ts-ignore
    [FORM_NAMES.AUTH_FORM]: { values },
  },
}) => values;

function* handleLoginRequest() {
  //@ts-ignore
  const values = yield select(getValues);

  try {
    const { data } = yield call(login, values);

    yield put(authSuccess({ success: 'Logged in successfully' }));
    yield delay(1500);
    yield put(loginUser({ isLogged: true, details: data.user }));
  } catch (e) {
    console.clear();
    //@ts-ignore
    yield put(loginUser({ isLogged: false }));
    try {
      const {
        data: { reason },
      } = e.response;

      if (reason) {
        yield put(authError({ error: reason }));

        yield delay(2500);
        yield put(authError({ error: null }));
      } else {
        throw e;
      }
    } catch (e) {
      yield put(authError({ error: 'Something went wrong' }));
      yield delay(2500);
      yield put(authError({ error: null }));
    }
  }
}

export function* watchOverLoginRequest() {
  yield takeEvery(AUTH.INIT_LOGIN, handleLoginRequest);
}
