import { Action } from "redux";
import { AUTH, UserModel } from "../@types";

interface AuthStateInterface {
  isLogged: boolean;
  error: null | string;
  loading: boolean;
  success: null | string;
  details?: UserModel;
}

interface AuthAction extends Action {
  payload: AuthStateInterface;
}

export function authReducer(
  state: AuthStateInterface = {
    isLogged: false,
    error: null,
    loading: false,
    success: null,
    details: {},
  },
  action: AuthAction
) {
  switch (action.type) {
    case AUTH.INIT_LOGIN:
      state.loading = true;
      return state;

    case AUTH.LOGIN:
      state.loading = false;
      return { ...state, ...action.payload };

    case AUTH.AUTH_ERROR:
      state.loading = false;
      return { ...state, ...action.payload };

    case AUTH.AUTH_SUCCESS:
      return { ...state, ...action.payload };

    case AUTH.LOGOUT:
      return {
        isLogged: false,
        error: null,
        loading: false,
        success: null,
        details: {},
      };

    default:
      return state;
  }
}
