import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import { store, persistor } from "./store";

import "./styles/scss/index.scss";
import "./utils/scripts";

import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <App />
    </Provider>
  </PersistGate>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
