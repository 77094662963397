import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import { reducers } from "./reducers";
import { rootSaga } from "./sagas";

const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();

const persistConfig = {
  key: "__root__",
  keyPrefix: "poyanil:",
  storage: storage,
  blacklist: ["form", "notifications"],
  // transforms: [
  //   encryptTransform({
  //     secretKey: 'zartektechnologies',
  //     onError: function (error) {
  //       console.log(error);
  //     },
  //   }),
  // ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = applyMiddleware(sagaMiddleware);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(middlewares)
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
