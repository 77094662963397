import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Menu } from '@material-ui/icons';
import { HeaderProps } from '../../../@types';

import { Avatar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Akaya Telivigala',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function Header(props: HeaderProps) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    isLogged,
    branding: { logo, title },
  } = props;

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: '#007aff' }}
      >
        <Toolbar>
          {isLogged && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => props.setShowDrawer((current) => !current)}
            >
              {props.showDrawerState ? <CloseIcon /> : <Menu />}
            </IconButton>
          )}
          <Avatar alt={title} src={logo} className="header-logo" />
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          {isLogged && (
            <Button
              onClick={() => dispatch(logoutUser())}
              variant="contained"
              color="secondary"
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
