import { useDispatch, useSelector } from "react-redux";

import {
  DecoratedComponentClass,
  DecoratedFormProps,
  Field,
  reduxForm,
} from "redux-form";
import { FORM_NAMES } from "../../@types";
import { loginUserInit } from "../../actions";
import { FormFields, validateAuthForm } from "./auth";

const AuthWindow: DecoratedComponentClass<
  FormFields,
  DecoratedFormProps<FormFields, {}, string>
> = reduxForm({
  form: FORM_NAMES.AUTH_FORM,
  validate: validateAuthForm,
})(({ handleSubmit }) => {
  const dispatch = useDispatch();

  const {
    user: { error, isLogged, loading, success },
  } = useSelector(
    (state: {
      form: any;
      user: {
        isLogged: boolean;
        error: string | null;
        loading: boolean;
        success: string | null;
      };
    }) => state
  );

  function dispatchAuth() {
    dispatch(loginUserInit());
  }

  if (isLogged) {
    return null;
  }

  return (
    <section id="auth-section">
      <hgroup>
        <h2>Welcome back!</h2>
        <p>Please enter your details to sign into your account</p>
      </hgroup>
      <form onSubmit={handleSubmit(dispatchAuth)} className="log-form">
        <div className="group log-input">
          <Field
            placeholder="Enter registered email"
            name="email"
            component="input"
            type="email"
            required
          />
        </div>
        <div className="group log-input">
          <Field
            type="password"
            name="password"
            component="input"
            placeholder="Password"
            required
          />
        </div>
        {error && <p className="error-text text-center">{error}</p>}
        {success && <p className="success-text text-center">{success}</p>}
        <div className="container-log-btn">
          {loading ? (
            <div className="loading-screen__spinner" />
          ) : (
            <button
              disabled={loading || isLogged}
              type="submit"
              name="btn_submit"
              className="log-form-btn"
            >
              <span>{isLogged ? "Please wait..." : "Login"}</span>
            </button>
          )}
        </div>
      </form>
    </section>
  );
});

export default AuthWindow;
