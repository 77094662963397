import { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Loader from '../../LoadingAnimation';
import { StyledTableCell, StyledTableRow, useStyles } from './style';
import { getMails } from '../../../apis';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../actions';
import { HOSPITALS, UserState, USER_ROLES } from '../../../@types';

import { HospitalDropDown } from '../../Shared/HospitalDropDown';

interface MailDateType {
  patientName?: string;
  doctor?: string;
  department?: string;
  patientNumber?: number;
  outPatientNumber?: number;
  replied?: boolean;
  message?: string;
  appointmentOn?: Date;
  patientEmail?: string;
  hospital?: string;
  _id?: string;
  createdAt?: Date;
}

export default function Mails() {
  const classes = useStyles();

  const [mailsRecieved, setMailsRecieved] = useState<MailDateType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    user: {
      details: { role },
    },
  } = useSelector((state: UserState) => state);

  const [selectedHospital, setSelectedHospital] = useState<HOSPITALS>(
    HOSPITALS.PROVIDENCE_HOSPITAL_ALAPPUZHA
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      let response: any;
      try {
        if (role === USER_ROLES.ADMIN) {
          const { data } = await getMails(currentPage, selectedHospital);
          response = data;
        } else {
          const { data } = await getMails(currentPage);
          response = data;
        }
        if (!isCancelled) {
          setPages(response.totalPages);
          setMailsRecieved(response.mails);
          setLoading(false);
        }
      } catch (e) {
        console.clear();
        if (!isCancelled) {
          setLoading(false);
        }
        dispatch(logoutUser());
        // alert("Cookie modified");
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [currentPage, dispatch, role, selectedHospital]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedHospital]);

  if (loading) {
    return <Loader />;
  }

  if (!mailsRecieved.length && !loading) {
    return (
      <>
        {role === USER_ROLES.ADMIN && (
          <HospitalDropDown
            setSelectedHospital={setSelectedHospital}
            selectedHospital={selectedHospital}
          />
        )}
        <h2>No mails</h2>
      </>
    );
  }

  return (
    <>
      {role === USER_ROLES.ADMIN && (
        <HospitalDropDown
          setSelectedHospital={setSelectedHospital}
          selectedHospital={selectedHospital}
        />
      )}

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ minWidth: '170px' }}>
                Patient name
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '190px' }}>
                Department
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '170px' }}>
                Doctor name
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '170px' }}>
                Appointment (recieved date)
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '150px' }}>
                Appointment (requested date)
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '150px' }}>
                Email
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '150px' }}>
                Phone
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '120px' }}>
                OPN
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: '370px' }}>
                Message
              </StyledTableCell>
              {/* <StyledTableCell>Replied</StyledTableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {mailsRecieved.map(
              ({
                _id,
                patientName,
                appointmentOn,
                department,
                doctor,
                outPatientNumber,
                patientEmail,
                patientNumber,
                message,
                replied,
                createdAt,
              }) => {
                return (
                  <StyledTableRow key={_id}>
                    <StyledTableCell style={{ textTransform: 'capitalize' }}>
                      {patientName || 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell style={{ textTransform: 'capitalize' }}>
                      {department || 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell>{doctor}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(`${createdAt}`)
                        .toString()
                        .split(' ')
                        .slice(0, 4)
                        .join(' ')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {new Date(`${appointmentOn}`)
                        .toString()
                        .split(' ')
                        .slice(0, 4)
                        .join(' ')}
                    </StyledTableCell>

                    <StyledTableCell>{patientEmail}</StyledTableCell>

                    <StyledTableCell>{patientNumber}</StyledTableCell>
                    <StyledTableCell>{outPatientNumber}</StyledTableCell>
                    <StyledTableCell>{message}</StyledTableCell>
                    {/* <StyledTableCell>
                      <Checkbox name="checkedD" checked={replied} disabled />
                    </StyledTableCell> */}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pages && (
        <div className="pagination-container">
          <Pagination
            onChange={(_event: object, page: number) => setCurrentPage(page)}
            count={pages > 10 ? 10 : pages}
            shape="rounded"
            page={currentPage}
          />
        </div>
      )}
    </>
  );
}
