import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
// import Divider from "@material-ui/core/Divider";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import ContentProvider from './ContentProvider';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: '60px',
    overflow: 'auto',
  },
}));

export enum contentTypes {
  MAILS = 'mails',
  CONTACTS = 'contacts',
  USERS = 'users',
}

export interface DashboardProps {
  openDrawer: boolean;
  setDrawerOpenState: Dispatch<SetStateAction<boolean>>;
}

export enum USER_ROLES {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  CO_WORKER = 'co_worker',
}

const Dashboard: FC<DashboardProps> = ({
  openDrawer = false,
  setDrawerOpenState,
}) => {
  const classes = useStyles();

  const {
    user: {
      details: { role },
    },
  } = useSelector(
    (state: {
      user: {
        details: {
          role: USER_ROLES;
        };
      };
    }) => state
  );

  const [selectedItem, setSelectedItem] = useState<contentTypes | null>(
    contentTypes.MAILS
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {openDrawer && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={openDrawer}
          onClick={() => setDrawerOpenState(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <ListItem
                button
                onClick={() => setSelectedItem(contentTypes.MAILS)}
              >
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={'Appointments'} />
              </ListItem>

              <ListItem
                button
                onClick={() => setSelectedItem(contentTypes.CONTACTS)}
              >
                <ListItemIcon>
                  <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary={'Contacts'} />
              </ListItem>

              {role === USER_ROLES.ADMIN && (
                <ListItem
                  button
                  onClick={() => setSelectedItem(contentTypes.USERS)}
                >
                  <ListItemIcon>
                    <SupervisedUserCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Users'} />
                </ListItem>
              )}
            </List>
            {/* <Divider /> */}
          </div>
        </Drawer>
      )}
      <main
        onClick={() => setDrawerOpenState(false)}
        className={classes.content}
      >
        <CssBaseline />
        <ContentProvider selectedItem={selectedItem} />
      </main>
    </div>
  );
};

export default Dashboard;
