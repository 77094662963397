import Axios from 'axios';
import { HOSPITALS } from '../@types';

const BASE_URL: string | undefined = process.env.REACT_APP_API_URI + '/api/v1';

export const apiRequest = Axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const login = (data: { email: string; password: string }) =>
  apiRequest.post(`/user/auth`, data);

export const getMails = (page: number, hospital?: HOSPITALS) => {
  if (hospital) {
    return apiRequest.get(
      `/service/get_mails?page=${page}&hospital=${hospital}`
    );
  } else {
    return apiRequest.get(`/service/get_mails?page=${page}`);
  }
};

export const getContactedList = (page: number, hospital?: HOSPITALS) => {
  if (hospital) {
    return apiRequest.get(
      `/service/get_contected_list?page=${page}&hospital=${hospital}`
    );
  } else {
    return apiRequest.get(`/service/get_contected_list?page=${page}`);
  }
};

export const addNewuser = (data: {
  email: string;
  password: string;
  hospital: string;
  role: string;
}) => apiRequest.post(`/user/add_user`, data);
