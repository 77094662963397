import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../Shared/Header';
import logo from '../../styles/img/logo.png';
import { UserState } from '../../@types';

interface LayoutProps {
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawerState: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  setShowDrawer,
  showDrawerState,
}) => {
  const {
    user: {
      isLogged,
      details: { hospital, role },
    },
  } = useSelector((state: UserState) => state);

  return (
    <div id="app" style={{ fontFamily: 'Roboto Slab' }}>
      <Header
        showDrawerState={showDrawerState}
        setShowDrawer={setShowDrawer}
        isLogged={isLogged}
        branding={{
          title:
            isLogged && role === 'admin' ? 'Poyanil' : isLogged ? hospital : '',
          logo: logo,
        }}
      />
      {children}
    </div>
  );
};

export default Layout;
