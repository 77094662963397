import { contentTypes } from ".";
import Mails from "./Mails";
import Contacts from "./Contacts";
import AddUser from "./AddUser";

export default function ContentProvider(props: {
  selectedItem: string | null;
}): JSX.Element {
  if (!props.selectedItem) {
    return <h1>Poyanil Hospital</h1>;
  }

  if (props.selectedItem === contentTypes.MAILS) {
    return <Mails />;
  }

  if (props.selectedItem === contentTypes.CONTACTS) {
    return <Contacts />;
  }

  if (props.selectedItem === contentTypes.USERS) {
    return <AddUser />;
  }

  return <></>;
}
