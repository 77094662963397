import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";

import { HospitalsDropDown, UserRoleDropDown } from "./DropDownSelector";
import { Dispatch, SetStateAction, useState } from "react";
import { addNewuser } from "../../../apis";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },

    form: {
      width: "380px",
      maxWidth: "80vw",
      display: "grid",
      gap: "5px",
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

function setErrorHelper(
  input: string,
  setError: Dispatch<SetStateAction<string | null>>
): void {
  setError(input);
  setTimeout(() => {
    setError(null);
  }, 2000);
}

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function AddUser() {
  const classes = useStyles();
  const [userEmail, setUserEmail] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hospital, setHospital] = useState<string>("");

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleAddUser(): Promise<void> {
    if (!userEmail.trim()) {
      return setErrorHelper("Enter email address", setError);
    }

    if (!validateEmail(userEmail)) {
      return setErrorHelper("Enter valid email address", setError);
    }
    if (!userRole.trim()) {
      return setErrorHelper("select a role for user", setError);
    }

    if (!password.trim()) {
      return setErrorHelper("provide a password for user", setError);
    }

    if (!hospital.trim()) {
      return setErrorHelper("select a hospital", setError);
    }

    try {
      setLoading(true);
      await addNewuser({
        email: userEmail,
        hospital,
        password,
        role: userRole,
      });
      setLoading(false);
      setSuccess("new user added");
      setTimeout(() => {
        setSuccess(null);
        setUserEmail("");
        setPassword("");
        setUserRole("");
        setHospital("");
      }, 2000);
    } catch (e) {
      setLoading(false);
      try {
        const { reason } = e.response;
        setErrorHelper(reason, setError);
      } catch (error) {
        setErrorHelper("something went wrong", setError);
      }
    }
  }

  return (
    <>
      <h2 className="text-center">Add Co worker / New Admin</h2>
      <div className={classes.root}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddUser();
          }}
          autoComplete="off"
          noValidate
        >
          <div className={classes.form}>
            <TextField
              id="standard-full-width email"
              label="Co Worker / Admin email"
              style={{ margin: 8 }}
              placeholder="Enter the email address of co-worker / admin"
              fullWidth
              value={userEmail}
              margin="normal"
              type="email"
              onChange={(e) => setUserEmail(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <UserRoleDropDown setUserRole={setUserRole} value={userRole} />

            <TextField
              id="standard-full-width pwd"
              label="Password"
              style={{ margin: 8 }}
              placeholder="Enter the password for admin / co-worker"
              fullWidth
              value={password}
              margin="normal"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <HospitalsDropDown setHospital={setHospital} value={hospital} />
            {error && <p className="error-text text-center">{error}</p>}
            {success && <p className="success-text text-center">{success}</p>}
            {loading ? (
              <div className="loading-screen__spinner" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
