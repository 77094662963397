export interface FormFields {
  email: string;
  password: string;
}

export const validateAuthForm: (formValues: FormFields) => {} = (
  formValues
) => {
  if (!formValues.email) {
    return "Enter email";
  }
  return true;
};
