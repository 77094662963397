import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { HOSPITALS } from '../../@types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface HospitalDropDownProps {
  setSelectedHospital: React.Dispatch<React.SetStateAction<HOSPITALS>>;
  selectedHospital: HOSPITALS;
}

export const HospitalDropDown = ({
  selectedHospital,
  setSelectedHospital,
}: HospitalDropDownProps) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel htmlFor="grouped-select">Select hospital</InputLabel>
      <Select
        onChange={(e) => {
          if (typeof e.target.value === 'string') {
            setSelectedHospital(e.target.value as HOSPITALS);
          }
        }}
        value={selectedHospital}
        id="grouped-select hsp"
      >
        <MenuItem value={HOSPITALS.PROVIDENCE_HOSPITAL_ALAPPUZHA}>
          Providence Hospital Alappuzha
        </MenuItem>
        <MenuItem value={HOSPITALS.POYANIL_HOSPITAL_KOZHENCHERRY}>
          Poyanil Hospital Kozhencherry
        </MenuItem>
        <MenuItem value={HOSPITALS.POYANIL_HOSPITAL_PUNALUR}>
          Poyanil Hospital Punalur
        </MenuItem>
      </Select>
    </FormControl>
  );
};
